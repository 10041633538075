import { ComponentType, useEffect, useState } from "react"

export default function withHorizontalDragy(Component): ComponentType {
    return (props) => {
        const [computedWidth, setComputedWidth] = useState(0)

        // get wrapper width
        const updateWrapperWidth = () => {
            const wrapperElement = document.querySelector(
                '[data-framer-name="Wrapper"]'
            )

            if (wrapperElement instanceof HTMLElement) {
                const width = wrapperElement.offsetWidth
                const newComputedWidth = (width - 260) / 2
                setComputedWidth(newComputedWidth)
            }
        }

        // get wrapper width after first render and listen to resizing of the window
        useEffect(() => {
            updateWrapperWidth()
            window.addEventListener("resize", updateWrapperWidth)
            return () =>
                window.removeEventListener("resize", updateWrapperWidth)
        }, [])

        return (
            <Component
                {...props}
                drag="x" // enable horizontal drag
                dragConstraints={{ left: -computedWidth, right: 0 }} // set left constraint directly
                dragTransition={{
                    power: 0.2, // lower the drag power
                    timeConstant: 100, // smooth the drag
                }}
                style={{ cursor: "grab" }}
            />
        )
    }
}
